<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Notifications") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <span class="switch switch-sm mx-5">
          <label>
            <input v-model="unread" type="checkbox" />
            <span></span>
          </label>
        </span>
        <label class="p-0 mt-2">{{ $t('Show only unread') }}</label>
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions" :tr-class="getRowClass">
        <!-- CELLS -->
        <template #[`cell.message`]="{ item }">
          <div class="ml-5">
            <span v-if="!item.data.actor_representation === getUserEmail" class="font-weight-bolder">{{ item.data.actor_representation }} </span>
            <span class="font-size-sm">{{ item.data.verb }} </span>
            <span v-if="item.data.action_object_representation" class="font-size-sm">{{ item.data.action_object_representation }} </span>
            <span v-if="item.data.target_content_type" class="font-size-sm">{{ $t("on")}} {{ item.data.target_representation }}</span>
          </div>
        </template>
        <template #[`cell.datetime`]="{ item }">
          <div class="font-weight-bold text-muted">
            {{ DateService.formatIfRecent(item.data.timestamp, date_format, timezone) }}
          </div>
        </template>
        <template #[`cell.actions`]="{ item }">
          <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm" :disabled="!item.data.unread"
            :title="$t('Mark as read')" @click="setNotificationAsRead(item.data.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Hidden.svg" />
            </span>
          </b-button>
          <b-button v-if="item.data?.data?.action" variant="light-primary" class="btn btn-primary btn-xs mr-3"
                    :title="item.data?.data?.action_label || this.$t('Go to') "
                    @click="performAction(item.data?.data?.action)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NotificationService from "@/core/services/notification/notification.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import eventBus from '@/core/services/event/eventBus.js';
import {backendErrorSwal} from "@/core/helpers/swal";

export default {
  components: {
    Datatable,
  },
  data() {
    return {
      DateService,
      unread: false,
      fields: [
        { label: this.$t("Message"), key: "message", class: ["font-weight-bolder"], sortable: false },
        { label: this.$t("Date"), key: "datetime", class: ["font-weight-bolder"], sortable: true },
        { label: this.$t("Actions"), key: "actions", class: ["font-weight-bolder"], sortable: false }
      ],
      total: 0,
      searchFilter: "",
      GENDERS: [],
      TELEPHONE_KINDS: [],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "date_format", "timezone", "getUserEmail"]),

    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
        "no-sort-reset": true,
      };
    },
  },

  watch: {
    unread() {
      this.refresh();
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Notifications") }
    ]);
  },

  methods: {
    itemProvider(ctx, callback) {
      NotificationService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy ? ctx.sortBy : 'timestamp',
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        unread: this.unread ? this.unread : null
        //fields: 'data.id,data.action_object_representation,data.level,data.unread,data.verb,data.timestamp,message'
      }).then((res) => {
        this.total = res.count;
        callback(res.results);
      }).catch((err) => {
        backendErrorSwal(err, this.$t("failed to load items"))
        callback([]);
      });

      return null;
    },

    setNotificationAsRead(id) {
      eventBus.$emit('read-notification', id);
      this.refresh()
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    getRowClass(item) {
      return item && item.data && !item.data.unread ? 'bg-light' : '';
    }
    
  },
};
</script>