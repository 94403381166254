var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Notifications")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('span', {
    staticClass: "switch switch-sm mx-5"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.unread,
      expression: "unread"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.unread) ? _vm._i(_vm.unread, null) > -1 : _vm.unread
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.unread,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.unread = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.unread = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.unread = $$c;
        }
      }
    }
  }), _c('span')])]), _c('label', {
    staticClass: "p-0 mt-2"
  }, [_vm._v(_vm._s(_vm.$t('Show only unread')))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "tr-class": _vm.getRowClass
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.message",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "ml-5"
        }, [!item.data.actor_representation === _vm.getUserEmail ? _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(item.data.actor_representation) + " ")]) : _vm._e(), _c('span', {
          staticClass: "font-size-sm"
        }, [_vm._v(_vm._s(item.data.verb) + " ")]), item.data.action_object_representation ? _c('span', {
          staticClass: "font-size-sm"
        }, [_vm._v(_vm._s(item.data.action_object_representation) + " ")]) : _vm._e(), item.data.target_content_type ? _c('span', {
          staticClass: "font-size-sm"
        }, [_vm._v(_vm._s(_vm.$t("on")) + " " + _vm._s(item.data.target_representation))]) : _vm._e()])];
      }
    }, {
      key: "cell.datetime",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bold text-muted"
        }, [_vm._v(" " + _vm._s(_vm.DateService.formatIfRecent(item.data.timestamp, _vm.date_format, _vm.timezone)) + " ")])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref3) {
        var _item$data, _item$data$data, _item$data2, _item$data2$data;
        var item = _ref3.item;
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "disabled": !item.data.unread,
            "title": _vm.$t('Mark as read')
          },
          on: {
            "click": function click($event) {
              return _vm.setNotificationAsRead(item.data.id);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Hidden.svg"
          }
        })], 1)]), (_item$data = item.data) !== null && _item$data !== void 0 && (_item$data$data = _item$data.data) !== null && _item$data$data !== void 0 && _item$data$data.action ? _c('b-button', {
          staticClass: "btn btn-primary btn-xs mr-3",
          attrs: {
            "variant": "light-primary",
            "title": ((_item$data2 = item.data) === null || _item$data2 === void 0 ? void 0 : (_item$data2$data = _item$data2.data) === null || _item$data2$data === void 0 ? void 0 : _item$data2$data.action_label) || this.$t('Go to')
          },
          on: {
            "click": function click($event) {
              var _item$data3, _item$data3$data;
              return _vm.performAction((_item$data3 = item.data) === null || _item$data3 === void 0 ? void 0 : (_item$data3$data = _item$data3.data) === null || _item$data3$data === void 0 ? void 0 : _item$data3$data.action);
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-arrow-right"
        })]) : _vm._e()];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }